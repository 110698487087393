import React, { useEffect, useRef } from 'react';
import './VideoPlayer.css';

function VideoPlayer({ title, url, startTime }) {
  // YouTube 동영상 ID 추출
  const videoId = extractVideoId(url);
  
  // YouTube 임베드 URL 생성
  const embedUrl = `https://www.youtube.com/embed/${videoId}?autoplay=1&start=${startTime}`;

  return (
    <div className="video-container">
      <iframe
        // width="560"
        // height="315"
        src={embedUrl}
        title={title}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    </div>
  );
}

// YouTube URL에서 비디오 ID를 추출하는 함수
function extractVideoId(url) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  const match = url.match(regExp);
  return (match && match[2].length === 11) ? match[2] : null;
}

export default VideoPlayer;
